import React from 'react'
import PropTypes from 'prop-types'
import { Slide } from '@material-ui/core'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

const HideOnScroll = (props) => {
    const { children } = props
    const trigger = useScrollTrigger()

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}
const useStyles = makeStyles((theme) =>
    createStyles({
        div: {
            height: 150,
            [theme.breakpoints.down('sm')]: {
                height: 128,
            },
        },
    })
)

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
}

export default function TopBar(props) {
    const classes = useStyles()
    const { hasHero } = props

    if (hasHero === false) {
        return <div style={{ height: 84 }}></div>
    }

    return <div className={classes.div}></div>
}
