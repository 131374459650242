import React from 'react';
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

const mixed = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

const defaultValues = {
  width: 50,
  height: 60,
  prevHtml: '&lsaquo;',
  nextHtml: '&rsaquo;',
}

const useStyles = makeStyles(theme => ({
  carouselControls: {
    position: 'absolute',
    top: '50%',
    height: 0,
    marginTop: '-30px',
    left: 0,
    width: '100%',
    zIndex: 1000,
  },

  nextPrev: {
    width: 60,
    position: 'absolute',
    zIndex: 1010,
    fontSize: 60,
    height: 60,
    lineHeight: '60px',
    color: '#333',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    textDecoration: 'none',
    top: 0,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    }
  },

  prev: {
    left: 200,
    textAlign: 'left',
  },

  next: {
    right: 200,
    textAlign: 'right',
  },

  disabled: {
    opacity: 0.2,
    cursor: 'default',
    '&:hover': {
      cursor: 'default',
      opacity: 0.2,
    }
  }
}))

const Controls = (receivedProps) => {
  const props = { ...defaultValues, ...receivedProps }
  const classes = useStyles();
  const nextEnabled = (props.isNextPossible) ? '' : 'disabled'
  const prevEnabled = (props.isPrevPossible) ? '' : 'disabled'

  return (
    <Hidden only="xs">
    <div className={classes.carouselControls}>
      {/* eslint-disable */}
      <div
        onClick={props.goPrev}
        onKeyPress={props.onKeyPressHandler}
        role="button"
        className={`${classes.nextPrev} ${classes.prev} ${prevEnabled}`}
        style={{ 
          width: props.width,
          height: props.height,
          lineHeight: props.lineHeight,
        }}>
        <span dangerouslySetInnerHTML={{__html: props.prevHtml}} />
      </div>
      <div
        onClick={props.goNext}
        onKeyPress={props.onKeyPressHandler}
        className={`${classes.nextPrev} ${classes.next} ${nextEnabled}`}
        role="button"
        style={{
          width: props.width,
          height: props.height,
          lineHeight: props.lineHeight,
        }}>
        <span dangerouslySetInnerHTML={{__html: props.nextHtml}} />
      </div>
  </div>
  </Hidden>
  )
}

Controls.propTypes = {
  width: mixed,
  height: mixed,
  prevHtml: PropTypes.string,
  nextHtml: PropTypes.string,
  goPrev: PropTypes.func,
  goNext: PropTypes.func,
  isNextPossible: PropTypes.bool,
  isPrevPossible: PropTypes.bool,
}

export default Controls;
