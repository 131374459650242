import { useState } from 'react';

export const DEFAULT_FIELD = {
  value: '',
  touched: false,
  valid: true,
  previous: undefined,
  error: null,
}

export const DEFAULT_BOOLEAN_FIELD = {
  value: false,
  touched: false,
  valid: true,
  previous: undefined,
  error: null,
}

export const submitFunction = ({ event, inputs, props, setInputs }) => ({
  result: {},
});

const useForm = (props, callback, fields, validators = {}) => {
  const [inputs, setInputs] = useState({ fields, errors: null, validators, valid: false });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const validateField = (name) => (value) => (inputs.validators[name]) ? inputs.validators[name](value, inputs) : { valid: true, error: null };
  const validateForm = (inputs) => {
    const fields = Object.keys(inputs.fields)
    const nextFields = fields.reduce((result, name) => {
      const { valid, error } = validateField(name)(inputs.fields[name].value);
      result[name] = {
        ...inputs.fields[name],
        value: inputs.fields[name].value,
        valid,
        error,
      };
      return result;
    }, {});
    const found = fields.find((name) => !nextFields[name].valid);
    return {
      ...inputs,
      fields: {
        ...inputs.fields,
        ...nextFields,
      },
      valid: (found === undefined),
    };
  }
  
  const handleBlur = (name) => (event) => {
    if (!inputs.fields[name]) return;
    if (!inputs.fields[name].value) return;
    if (inputs.fields[name].value === '') return; 
    const { valid, error } = validateField(name)(inputs.fields[name].value);
    setInputs(inputs => (
      {...inputs,
        fields: {
          ...inputs.fields,
          [name]:
          {
            ...inputs.fields[name],
            valid,
            error,
          }
        }
      }
    ));
  }
  const handleFocus = (name) => (event) => {
  }

  const handleSubmit = async (event, inputs) => {
    event.preventDefault();
    event.stopPropagation();
    setSuccess(false);
    const validatedForm = validateForm(inputs);
    const nextInputs = {
      ...inputs,
      fields: validatedForm.fields,
      valid: validatedForm.valid,
    };
    if (validatedForm.valid === false) {
      setInputs(inputs => nextInputs);
    } else {
      setLoading(true);
      const { errors } = await callback({ event, inputs, props, setInputs });
      if (!errors) setSuccess(true);
      setInputs(inputs => ({ ...inputs, valid: false, errors: [errors] }));
      setLoading(false);
    }
  }

  const handleCheckboxChange = name => event => {
    setInputs(inputs =>
      ({
        ...inputs,
        fields: {
          ...inputs.fields,
          [name]:
          {
            previous: inputs.fields[name].value, 
            value: event.target.checked, 
            touched: true
          }
        }
      })
    );
    event.persist();
  };

  const handleInputChange = name => event => {
    const value = event.target.value;
    setInputs(inputs =>
      ({
        ...inputs,
        fields: {
          ...inputs.fields,
          [name]:
          {
            previous: inputs.fields[name].value, 
            value: value, 
            touched: true
          }
        }
      })
    );
 }
  return {
    handleBlur,
    handleFocus,
    handleSubmit,
    handleInputChange,
    handleCheckboxChange,
    inputs,
    loading,
    setLoading,
    success,
    setSuccess,
  };
}

export default useForm;
