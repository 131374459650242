const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  measurementId: process.env.GATSBY_MEASUREMENT_ID,
  appId: process.env.GATSBY_APP_ID,
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.serverTime = () => app.firestore.Timestamp.now().toDate();
    
    /* Firebase APIs */

    this.auth = app.auth();
    this.functions = app.functions();
    /* db */
    const settings = {
      host: (process.env.GATSBY_CHINA_MODE === 'on') ?
        process.env.GATSBY_CHINA_DB_HOST || 'db1.melioeducation.com'
        : 'firestore.googleapis.com',
    };
    app.firestore().settings(settings);
    this.db = app.firestore();

    if (process.env.GATSBY_CHINA_MODE === 'on') {
      const fnHost = process.env.GATSBY_CHINA_FUNCTIONS_HOST || `fn1.melioeducation.com`;
      this.functions._url = name => {
        return `https://${fnHost}/${name}`;
      };
    }
    this.storage = app.storage();
  }

  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithEmailLink = (email, link) =>
    this.auth.signInWithEmailLink(email, link);

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

  doSendSignInLinkToEmail = (email) =>
    this.auth.sendSignInLinkToEmail(email, {
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT + '/app/account',
      handleCodeInApp: true,
    });

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  isAdmin = user => user.roles['ADMIN'] === 'ADMIN';

}

let firebase;

function getFirebase(app, auth, database, functions, storage) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, functions, storage);
  }

  return firebase;
}

export default getFirebase;
